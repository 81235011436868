
.NavStyle {
	text-align: left;
	padding: 12px 12px;
}

.MainStyle {
	text-align: left;
	max-width: 1024px;
	padding: 12px 12px;

	/* Center the website. */
	margin: 0 auto;


	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 
		'Droid Sans', 'Helvetica Neue', sans-serif;

 	-webkit-font-smoothing: antialiased;
 	-moz-osx-font-smoothing: grayscale;
}

em {
	font-weight: bold;
}

p + ul {
	margin-top: -12px;
}

p + ol {
	margin-top: -12px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
}